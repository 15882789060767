import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';

import { SlotId } from '../config';
import { bonusIds, GameMode, ReelSet, reelSets } from '../global.d';
import { IConfig } from '../gql/d';
import SentryRaven from '../sentryRaven';
import SpineAnimation from '../slotMachine/animations/spine';
import { Icon } from '../slotMachine/d';
import { setGameMode } from '../gql';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap = (
  fn: CallableFunction,
  ...partOne: Helper.RestArguments
) => (...partTwo: Helper.RestArguments): unknown => {
  const args: Helper.RestArguments = [...partOne, ...partTwo];
  if (args.length) {
    return fn(...args);
  }
  return fn();
};

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    if (config[name] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * 20) / 100;
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

// const createFastBetArrMax = (minBet: number, maxBet: number) => {
//   const cef = maxBet / minBet / 10;
//   const arr = new Array(10).fill(minBet);
//   return arr.map((item, index) => {
//     const v = index ? index + 1 : 1;
//     const i = Math.floor(cef * v);
//     return item * i;
//   });
// };

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Array<Icon>, id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  SentryRaven.captureException<Error>(error);
  throw error;
};
export const getSpinResult3x5 = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Array<Icon>;
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom =
        random - 1 < 0 ? reelSet.layout[index].length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index][prevRandom]);
    }),
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index][random]);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom =
        random + 1 >= reelSet.layout[index].length ? 0 : random + 1;
      return getIconById(icons, reelSet.layout[index][nextRandom]);
    }),
  ];
  return spinResult;
};
export const getSpinResult4X5 = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Array<Icon>;
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom =
        random - 1 < 0 ? reelSet.layout[index].length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index][prevRandom]);
    }),
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index][random]);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = (random + 1) % reelSet.layout[index].length;
      return getIconById(icons, reelSet.layout[index][nextRandom]);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = (random + 2) % reelSet.layout[index].length;
      return getIconById(icons, reelSet.layout[index][nextRandom]);
    }),
  ];
  return spinResult;
};
export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  localStorage.setItem('positions', btoa(positions));
};
export const destroySpine = (spine: SpineAnimation): void => {
  setImmediate(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy();
    }
  });
};
export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return (
    mode === GameMode.FREE_SPINS_LVL1 ||
    mode === GameMode.FREE_SPINS_LVL2 ||
    mode === GameMode.FREE_SPINS_LVL3 ||
    mode === GameMode.FREE_SPINS_LVL4 ||
    mode === GameMode.FREE_SPINS_LVL5
  );
};

export const isFreeRoundBonusMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_ROUND_BONUS;
};

export const isScatter = (slotId: SlotId): boolean => {
  return (
    slotId === SlotId.SC1 ||
    slotId === SlotId.SC2 ||
    slotId === SlotId.SC3 ||
    slotId === SlotId.SC4 ||
    slotId === SlotId.SC5 ||
    slotId === SlotId.SC6
  );
};

export const getLevelByGameMode = (gameMode: GameMode): number => {
  if (gameMode === GameMode.FREE_SPINS_LVL1) return 1;
  if (gameMode === GameMode.FREE_SPINS_LVL2) return 2;
  if (gameMode === GameMode.FREE_SPINS_LVL3) return 3;
  if (gameMode === GameMode.FREE_SPINS_LVL4) return 4;
  if (gameMode === GameMode.FREE_SPINS_LVL5) return 5;
  if (gameMode === GameMode.FREE_ROUND_BONUS) return 6;
  return 0;
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  if (reelSets[GameMode.FREE_SPINS_LVL1] === reelSetId)
    return GameMode.FREE_SPINS_LVL1;
  if (reelSets[GameMode.FREE_SPINS_LVL2] === reelSetId)
    return GameMode.FREE_SPINS_LVL2;
  if (reelSets[GameMode.FREE_SPINS_LVL3] === reelSetId)
    return GameMode.FREE_SPINS_LVL3;
  if (reelSets[GameMode.FREE_SPINS_LVL4] === reelSetId)
    return GameMode.FREE_SPINS_LVL4;
  if (reelSets[GameMode.FREE_SPINS_LVL5] === reelSetId)
    return GameMode.FREE_SPINS_LVL5;
  return GameMode.REGULAR;
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  if (bonusIds[GameMode.FREE_SPINS_LVL1] === bonusId)
    return GameMode.FREE_SPINS_LVL1;
  if (bonusIds[GameMode.FREE_SPINS_LVL2] === bonusId)
    return GameMode.FREE_SPINS_LVL2;
  if (bonusIds[GameMode.FREE_SPINS_LVL3] === bonusId)
    return GameMode.FREE_SPINS_LVL3;
  if (bonusIds[GameMode.FREE_SPINS_LVL4] === bonusId)
    return GameMode.FREE_SPINS_LVL4;
  if (bonusIds[GameMode.FREE_SPINS_LVL5] === bonusId)
    return GameMode.FREE_SPINS_LVL5;
  if (bonusIds[GameMode.FREE_ROUND_BONUS] === bonusId)
    return GameMode.FREE_ROUND_BONUS;
  return GameMode.REGULAR;
};

export const calcPercentage = (
  initialValue: number,
  percent: number,
): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  bonusCurrentRound,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isPopupOpened,
  transitionStarted,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isPopupOpened?: boolean;
  transitionStarted?: boolean;
}): boolean => {
  if (transitionStarted) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  if (!isFreeSpinsMode(setGameMode()) && isFreeSpinsWin) {
    return false;
  }

  if (isFreeSpinsMode(gameMode) && (bonusCurrentRound === 0 || !isSlotBusy)) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (options: Partial<DropShadowFilterOptions>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  return new DropShadowFilter(options) as PIXI.Filter;
};

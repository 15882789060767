import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setGameMode, setIsPortrait } from '../../gql';
import { destroySpine, isFreeRoundBonusMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_WIDTH, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

class GameTitle extends ViewContainer {
  private spine: SpineAnimation | undefined;

  constructor() {
    super();
    eventManager.on(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => {
      this.spine!.getSpine().visible = settings.mode === GameMode.REGULAR || isFreeRoundBonusMode(setGameMode());
    });
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, (settings: { mode: GameMode }) => {
      this.spine!.getSpine().visible = settings.mode === GameMode.REGULAR || isFreeRoundBonusMode(setGameMode());
    });
    eventManager.addListener(EventTypes.CHANGE_DEVICE_ORIENTATION, (isPortrait: boolean) => {
      this.initGameLogo(isPortrait);
    });
    this.initGameLogo(setIsPortrait());
  }

  private initGameLogo(isPortrait: boolean): void {
    if (this.spine) {
      this.removeChild(this.spine.getSpine());
      destroySpine(this.spine);
    }
    this.spine = new SpineAnimation({}, PIXI.Loader.shared.resources.intro_transition.spineData);
    this.spine.getSpine().visible = !setBrokenGame() && setGameMode() === GameMode.REGULAR;
    this.spine.addOnStart(() => {
      const spineData = this.spine!.getSpine();
      spineData.x = GAME_CONTAINER_WIDTH / 2 + 7;
      spineData.y = 50;
      if (!isPortrait) {
        this.spine?.spine.scale.set(0.3, 0.3);
      } else {
        this.spine?.spine.scale.set(1.6, 1.6);
      }
      this.addChild(spineData);
      this.spine!.setAnimation(isPortrait ? 'logo_idle_portrait' : 'logo_idle', true);
    });
    this.spine.start();
  }
}

export default GameTitle;

import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setIsPortrait, setIsTransitionStarted } from '../../gql';
import { destroySpine, isFreeRoundBonusMode, updateCoinValueAfterBonuses } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  FREE_SPINS_FADE_DURATION,
  GAME_CONTAINER_WIDTH,
  LANDSCAPE_FULL_SCREEN_HEIGHT,
  LANDSCAPE_FULL_SCREEN_WIDTH,
  PORTRAIT_FULL_SCREEN_HEIGHT,
  PORTRAIT_FULL_SCREEN_WIDTH,
  eventManager,
} from '../config';

class FadeArea extends ViewContainer {
  private sprite: PIXI.Sprite;

  constructor() {
    super();
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.sprite.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.START_INTRO_ANIMATION, this.startIntroAnimation.bind(this));
    eventManager.addListener(EventTypes.START_RETRIGGER_ANIMATION, this.startRetriggerAnimation.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.START_MODE_CHANGE_FADE, this.startModeChangeFade.bind(this));
  }

  private startIntroAnimation(): void {
    const group = new AnimationGroup();
    const delay = Tween.createDelayAnimation(200);
    delay.addOnComplete(() => eventManager.emit(EventTypes.SHOW_SAFE_AREA));
    group.addAnimation(delay);
    const spine = new SpineAnimation({}, PIXI.Loader.shared.resources.intro_transition.spineData);

    spine.addOnStart(() => {
      const spineData = spine.getSpine();
      spineData.x = this.width / 2;
      spineData.y = this.height / 2;
      if (setIsPortrait()) {
        spineData.scale.set(this.width / PORTRAIT_FULL_SCREEN_WIDTH, this.height / PORTRAIT_FULL_SCREEN_HEIGHT);
      } else {
        spineData.scale.set(this.width / LANDSCAPE_FULL_SCREEN_WIDTH, this.height / LANDSCAPE_FULL_SCREEN_HEIGHT);
      }
      this.addChild(spineData);
      spine.setAnimation(setIsPortrait() ? 'intro_portrait' : 'intro', false);
    });
    spine.addOnComplete(() => destroySpine(spine));
    group.addAnimation(spine);
    group.start();
  }

  private startRetriggerAnimation(): void {
    const spine = new SpineAnimation({}, PIXI.Loader.shared.resources.intro_transition.spineData);

    spine.addOnStart(() => {
      const spineData = spine.getSpine();
      spineData.x = this.width / 2;
      spineData.y = this.height / 2;
      if (setIsPortrait()) {
        spineData.scale.set(this.width / PORTRAIT_FULL_SCREEN_WIDTH, this.height / PORTRAIT_FULL_SCREEN_HEIGHT);
      } else {
        spineData.scale.set(this.width / LANDSCAPE_FULL_SCREEN_WIDTH, this.height / LANDSCAPE_FULL_SCREEN_HEIGHT);
      }

      this.addChild(spineData);
      spine.setAnimation(setIsPortrait() ? 'transition_portrait' : 'transition_landscape', false);
    });
    spine.addOnStart(() =>
      AudioHowl.play({
        type: ISongs.LvUpBanner,
      }),
    );
    spine.addOnComplete(() => destroySpine(spine));
    spine.start();
  }

  private resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }

  private startModeChangeFade(settings: { mode: GameMode; reelPositions: number[]; reelSetId: string }): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, FREE_SPINS_FADE_DURATION / 2);
    fadeOut.addOnComplete(() => {
      if (settings.mode === GameMode.REGULAR || isFreeRoundBonusMode(settings.mode)) {
        updateCoinValueAfterBonuses();
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }
      eventManager.emit(EventTypes.CHANGE_MODE, settings);
    });
    const fadeIn = this.getFadeAnimation(0, FREE_SPINS_FADE_DURATION / 2);
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.addOnComplete(() => {
      setIsTransitionStarted(false);
      eventManager.emit(EventTypes.END_MODE_CHANGE_FADE);
    });

    animationChain.start();
  }

  private getFadeAnimation(alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: alpha === 1 ? 0 : 1,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}

export default FadeArea;

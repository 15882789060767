import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setGameMode, setIsPortrait } from '../../gql';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import {
  eventManager,
} from '../config';

class Background extends ViewContainer {
  private baseBg = PIXI.Texture.from('slotBg');

  private portraitBaseBg = PIXI.Texture.from('portrait_slotBg');

  private lvl1_2Bg = PIXI.Texture.from('freeSpinsBg1');

  private portraitlvl1_2Bg = PIXI.Texture.from('portrait_freeSpinsBg1');

  private lvl3_4Bg = PIXI.Texture.from('freeSpinsBg2');

  private portraitlvl3_4Bg = PIXI.Texture.from('portrait_freeSpinsBg2');

  private lvl5_6Bg = PIXI.Texture.from('freeSpinsBg3');

  private portraitlvl5_6Bg = PIXI.Texture.from('portrait_freeSpinsBg3');

  private bgSprite = new PIXI.Sprite();

  private animations: SpineAnimation[] = [];

  private isPortrait: boolean;

  constructor() {
    super();
    this.isPortrait = setIsPortrait();
    this.bgSprite.texture = this.isPortrait ? this.portraitBaseBg : this.baseBg;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeModeBackground.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeModeBackground.bind(this));
    eventManager.addListener(
      EventTypes.CHANGE_DEVICE_ORIENTATION,
      (isPortrait: boolean, width: number, height: number) => {
        this.changeModeBackground({ mode: setGameMode() }, isPortrait);
        this.resize(width, height);
      },
    );
    this.initRegularAnimations(this.isPortrait);
  }

  private initRegularAnimations(isPortrait: boolean): void {
    const ambientBaseAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.ambient.spineData);
    ambientBaseAnimation.addOnStart(() => {
      this.addChild(ambientBaseAnimation.spine);
      ambientBaseAnimation.setAnimation(isPortrait ? 'portrait_ambient_base' : 'ambient_base', true);
    });
    this.animations.push(ambientBaseAnimation);
    ambientBaseAnimation.start();
  }

  private initFreeSpinsLevel12Animations(isPortrait: boolean): void {
    const ambientFSAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.ambient.spineData);
    ambientFSAnimation.addOnStart(() => {
      this.addChild(ambientFSAnimation.getSpine());
      ambientFSAnimation.setAnimation(isPortrait ? 'portrait_ambient_fs_lvl_1_2' : 'ambient_fs_lvl_1_2', true);
    });
    this.animations.push(ambientFSAnimation);
    ambientFSAnimation.start();
  }

  private initFreeSpinsLevel34Animations(isPortrait: boolean): void {
    const ambientFSAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.ambient.spineData);
    ambientFSAnimation.addOnStart(() => {
      this.addChild(ambientFSAnimation.getSpine());
      ambientFSAnimation.setAnimation(isPortrait ? 'portrait_ambient_fs_lvl_3_4' : 'ambient_fs_lvl_3_4', true);
    });
    this.animations.push(ambientFSAnimation);
    ambientFSAnimation.start();
  }

  private initFreeSpinsLevel5Animations(isPortrait: boolean): void {
    const ambientFSAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.ambient.spineData);
    ambientFSAnimation.addOnStart(() => {
      this.addChild(ambientFSAnimation.getSpine());
      ambientFSAnimation.setAnimation(isPortrait ? 'portrait_ambient_fs_lvl_5' : 'ambient_fs_lvl_5', true);
    });
    this.animations.push(ambientFSAnimation);
    ambientFSAnimation.start();
  }

  private changeModeBackground(settings: { mode: GameMode }, isPortrait = setIsPortrait()) {
    const { mode } = settings;
    this.animations.forEach((animation) => {
      this.removeChild(animation.getSpine());
      destroySpine(animation);
    });
    this.animations = [];
    if (mode === GameMode.REGULAR || mode === GameMode.FREE_ROUND_BONUS) {
      this.bgSprite.texture = isPortrait ? this.portraitBaseBg : this.baseBg;
      this.initRegularAnimations(isPortrait);
    }
    if (mode === GameMode.FREE_SPINS_LVL1 || mode === GameMode.FREE_SPINS_LVL2) {
      this.bgSprite.texture = isPortrait ? this.portraitlvl1_2Bg : this.lvl1_2Bg;
      this.initFreeSpinsLevel12Animations(isPortrait);
    }
    if (mode === GameMode.FREE_SPINS_LVL3 || mode === GameMode.FREE_SPINS_LVL4) {
      this.bgSprite.texture = isPortrait ? this.portraitlvl3_4Bg : this.lvl3_4Bg;
      this.initFreeSpinsLevel34Animations(isPortrait);
    }
    if (mode === GameMode.FREE_SPINS_LVL5) {
      this.bgSprite.texture = isPortrait ? this.portraitlvl5_6Bg : this.lvl5_6Bg;
      this.initFreeSpinsLevel5Animations(isPortrait);
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;

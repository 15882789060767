import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql';
import { Icon, LineSet } from './slotMachine/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      isBonus: boolean;
      bonuses: UserBonus[];
      features: {
        isFreeSpins: boolean;
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
  rewards: BetReward[];
}
export type BetRewardType = 'BetBonusReward' | 'BetCoinReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  currentRound: number;
  totalRounds: number;
  betId: string;
  rounds: number;
  reelSetId: string;
  bonus: Bonus;
  totalWinAmount;
  coinValue: number;
  coinAmount: number;
  gameMode: GameMode;
  status: BonusStatu;
  data?: UserBonusData;
  isFreeBet?: boolean;
};


export type UserBonusData = {
  frbReferenceId: string | null;
};

export type Bonus = {
  id: string;
  slotId: string;
  reelSetId: string;
  type: BonusType;
  title?: string;
  description?: string;
  lineSetId: string | null;
  coinAmount: number;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};
export enum GameMode {
  REGULAR,
  FREE_SPINS_LVL1,
  FREE_SPINS_LVL2,
  FREE_SPINS_LVL3,
  FREE_SPINS_LVL4,
  FREE_SPINS_LVL5,
  FREE_ROUND_BONUS,
}
export const reelSets = {
  [GameMode.REGULAR]: 'a11cadcd-28bf-4e6c-8eca-a7d9d7cb6217',
  [GameMode.FREE_SPINS_LVL1]: 'ff4331fb-6b61-46e3-b5f4-01dd140c4061',
  [GameMode.FREE_SPINS_LVL2]: '78ebfe79-a9c2-4a11-b667-d358ba496aed',
  [GameMode.FREE_SPINS_LVL3]: '97efbc9f-a69b-416f-b458-040f6de63b21',
  [GameMode.FREE_SPINS_LVL4]: '0bfbb40c-6776-4b7a-81dd-1ab60ef43498',
  [GameMode.FREE_SPINS_LVL5]: '2d01a23a-fe97-49e2-8ebc-d3b159d97ae2',
  [GameMode.FREE_ROUND_BONUS]: 'a11cadcd-28bf-4e6c-8eca-a7d9d7cb6217',
};

export const bonusIds: { [key in GameMode]: string } = {
  [GameMode.FREE_SPINS_LVL1]: 'e4f0c35a-2520-41dc-a4c2-aed05f67222e',
  [GameMode.FREE_SPINS_LVL2]: 'fdd3d4f8-2646-4082-bb14-b517175a8ce2',
  [GameMode.FREE_SPINS_LVL3]: '76b46a50-668c-420e-b761-e0ba83c41296',
  [GameMode.FREE_SPINS_LVL4]: '73383030-f12d-45a0-a5c2-7b1ab00056ea',
  [GameMode.FREE_SPINS_LVL5]: '9b6ad180-5377-40db-b321-408f91b52dfd',
  [GameMode.FREE_ROUND_BONUS]: 'b1e8cbcb-cf25-40b7-a83f-4ded3e6c2f06',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title: string;
  subTitle: string;
  btnText: string;
  freeSpinsSubtitle: string;
  titleLevel: string;
  winTitle: string;
  winTotalSpins: string;
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE_BACKGROUND = 'changeModeBackground',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CHANGE_DEVICE_ORIENTATION = 'changeDeviceOrientation',
  // t('freeSpinsTitleText')
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_RETRIGGER_MESSAGE_BANNER = 'createRetriggerMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',

  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  JINGLE_START = 'jingleStart',
  HIDE_COINS = 'hideCoins',
  HIDE_GAME_TITLE = 'hideGameTitle',
  HIDE_BONUS_TITLE = 'hideBonusTitle',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_SAFE_AREA = 'hideSaveArea',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_INTRO_ANIMATION = 'startIntroAnimation',
  START_RETRIGGER_ANIMATION = 'startRetrigger',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_WIN_LABEL = 'showWinLabel',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_TINT = 'showTint',
  SHOW_SAFE_AREA = 'showSafeArea',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SHOW_GAME_TITLE = 'showGameTitle',
  SHOW_MAX_LEVEL_LABEL = 'showMaxLevelLabel',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SET_NEXT_FREE_SPINS_LEVEL = 'setNextFreeSpinsLevel',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  REMOVE_ANTICIPATION_TINT = 'removeAnticipationTint',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  HANDLE_ACTIVE_FREE_SPINS_GAME = 'handleActiveFreeSpinsGame',
  IS_ENABLED_FREE_SPINS_FEATURE = 'isEnabledFreeSpinsFeature',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  SET_LAST_BET_RESULT_AFTER_FREE_SPINS = 'setLastBetResultAfterFreeSpins',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
  END_MODE_CHANGE_FADE = 'endModeChangeFade',
  OPEN_POPUP = 'openPopup',
  CLOSE_POPUP = 'closePopup',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

declare global {
  interface Window {
    __ENV__: {
      REACT_APP_TRANSLATE_URL: string;
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    Howler: {
      _spriteBaseUrl: string;
    };
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  NONE = null,
}
